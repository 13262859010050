<template>
  <div class="langtoggle">
    <CDropdown :show.sync="showDropdown">
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " v-if="$i18n.locale === 'th'" />
            <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " v-else />
          </div>
        </CHeaderNavLink>
      </template>
      <label>
        <CDropdownItem v-if="$i18n.locale === 'en'" @click="changeLocale('th')">
          <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " @click="changeLocale('th')" />
          </div>
          <span style="margin-left: 20px;"> ภาษาไทย </span>
        </CDropdownItem>
      </label>
      <label>
        <CDropdownItem v-if="$i18n.locale === 'th'" @click="changeLocale('en')">
          <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " @click="changeLocale('en')" />
          </div>
          <span style="margin-left: 20px;"> English </span>
        </CDropdownItem>
      </label>
    </CDropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showDropdown: false,
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.showDropdown = false
    },
  },
}
</script>

<style scoped>
.langtoggle {
  align-items: end;

}
</style>
