<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand style="padding:12px">
      <a href="/" class="text-center">
        <img :src="logo" class="c-sidebar-brand-full" width="60%" alt="" />
        <img
          :src="favIcon"
          class="c-sidebar-brand-minimized"
          width="40"
          alt=""
        />
      </a>
    </CSidebarBrand>
    <span hidden>{{ registerShow }}</span>
    <CSidebarNav>
      <CSidebarNavItem icon="cil-home" :name="this.$i18n.t('home')" to="/">
      </CSidebarNavItem>
      <CSidebarNavItem
        icon="cib-line"
        :name="this.$i18n.t('member.register')"
        to="/reward/register"
        v-if="showRegister === true"
      >
      </CSidebarNavItem>
      <CSidebarNavItem
        icon="cil-history"
        to="/transaction/history"
        :name="this.$i18n.t('transection')"
      >
      </CSidebarNavItem>
      <CSidebarNavItem :name="this.$i18n.t('member.loyaltyPoint')" icon="cil-gift" to="/loyalty-point">
      </CSidebarNavItem>
      <CSidebarNavItem
        :name="this.$i18n.t('loyaltyCard')"
        icon="cil-credit-card"
        to="/rewardcard"
      >
      </CSidebarNavItem>
      <CSidebarNavItem
        icon="cil-credit-card"
        to="/voucher"
        :name="this.$i18n.t('member.giftVoucher')"
      >
      </CSidebarNavItem>
      <CSidebarNavItem
        :name="this.$i18n.t('member.storeCredit')"
        to="/store-credit"
        icon="cil-wallet"
        class="font-weight-normal"
        :badge="{ color: 'success', text: 'coming soon', class: 'font-weight-normal' }"
      >
      </CSidebarNavItem>
      <CSidebarNavItem
        icon="cil-burger"
        to="/prepaid-items"
        :name="this.$i18n.t('member.deal')"
        :badge="{ color: 'success', text: 'coming soon', class: 'font-weight-normal'  }"
      >
      </CSidebarNavItem>
      <CSidebarNavItem
        icon="cil-sitemap"
        to="/referral"
        :name="this.$i18n.t('member.referral')"
        :badge="{ color: 'success', text: 'coming soon', class: 'font-weight-normal'  }"
      >
      </CSidebarNavItem>
      <CSidebarNavItem icon="cil-settings" to="/crm/settings" :name="this.$i18n.t('setting')">
      </CSidebarNavItem>
      <CSidebarNavItem icon="cil-book" to="/manual" :name="this.$i18n.t('systemUserManual')">
      </CSidebarNavItem>

      <CSidebarNavDivider class="m-2"></CSidebarNavDivider>
      <CSidebarNavTitle><div class="font-weight-normal">{{ $t('memberMenu') }}</div></CSidebarNavTitle>
      <CSidebarNavItem :name="this.$i18n.t('textMember')" to="/member" icon="cil-user-follow">
      </CSidebarNavItem>

      <CSidebarNavDivider class="m-2"></CSidebarNavDivider>
      <CSidebarNavTitle><div class="font-weight-normal">การจัดการ</div></CSidebarNavTitle>
      <CSidebarNavItem :name="this.$i18n.t('management.shopBranch')" to="/shop" icon="cil-basket">
      </CSidebarNavItem>
      <CSidebarNavItem :name="this.$i18n.t('currentUser')" to="/user" icon="cil-user">
      </CSidebarNavItem>
      <!-- <br />
      <br />
      <CSidebarNavItem
        name="ออกจากระบบ"
        to="/user/logout"
        icon="cil-account-logout"
      >
      </CSidebarNavItem> -->
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      minimize: false,
      show: 'responsive',
      logo: '/logo-white.png',
      favIcon: '/silom-pos.png',
      showRegister: '',
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    loginwith() {
      return localStorage.getItem('loginwith')
    },
    registerShow() {
      if (this.shops.length !== 0) {
        if (
          this.shops[0].isLoyaltyEnabled === undefined ||
          this.shops[0].isLoyaltyEnabled === null
        ) {
          this.showRegister = true
        } else {
          this.showRegister = false
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('getUser')
    this.$store.dispatch('getShop')
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
  },
}
</script>
