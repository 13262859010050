<template>

  <CHeader fixed light class="d-flex">
    <CToggler
      in-header
      class="p-2 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="p-2 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />

    <CHeaderNav class="ml-auto p-2">
      <TheHeaderAcc />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderAcc from './TheHeaderAcc'
export default {
  components: {
    TheHeaderAcc,
  },
}
</script>
